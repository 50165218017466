import Link from 'next/link'
import parse from 'html-react-parser'
import dynamic from 'next/dynamic'
import Scripts from '../components/scripts'

import Header from '../components/header'
import Slider from '../components/slider'
import Searcher from '../components/searcher/searcher'
import Image from 'next/image'
import Script from 'next/script'
import { React, useState, useEffect } from 'react'

const Footer = dynamic(() => import('../components/footer'))
const MiniCart = dynamic(() => import('../components/cart/minicart'))
const Featuredproducts = dynamic(() => import('../components/featured/featuredproducts'))
const Featuredtaxonomies = dynamic(() => import('../components/featured/featuredtaxonomies'))


import { setLatestbloghide } from '../redux/latestbloghide.slice'
import store from '../redux/store';
import { useSelector } from 'react-redux';
import MetaTags from '../components/MetaTags'

function Home({ item, menu, config, featured_products, featured_taxonomies, sliders, latest_blog_post, trends }) {
  const latestbloghideobject = useSelector((state) => state.latestbloghide);

  const hide_latest_blog_post = (id) => {
    store.dispatch(setLatestbloghide(id))
  }
  const is_latest_blog_post_hidden = (id) => {
    // check the object for our quantity
    var match = latestbloghideobject.findIndex((element) => element == id)
    if (match >= 0) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = 'https://cdn.jsdelivr.net/gh/stevenschobert/instafeed.js@2.0.0rc1/src/instafeed.min.js';
    script.async = true;

    document.body.appendChild(script);
    script.onload = function () {
      new Instafeed({
        get: 'user',
        target: "instafeed-container",
        resolution: 'low_resolution',
        accessToken: config.instagramfeedtoken,
        template: '<a target="_blank" href="{{link}}" style="display:block;width: 360px;margin-right: 30px;"><img style="max-width: none;height: 360px;display: block;width: 100%;object-fit: cover;" title="{{caption}}" src="{{image}}" /></a>'
      }).run();
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);


  const slideLeft = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft - 300;
  };

  const slideRight = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft + 300;
  };
  return (
    <>
      <MetaTags item={item} config={config}></MetaTags>


      <div className="wrapper h-full flex flex-col">
        <Header menu={menu} config={config}></Header>

        {(latest_blog_post && config.blog_banner_enabled && config.blog_banner_enabled == 'true') ? (
          <>
            {is_latest_blog_post_hidden(latest_blog_post.id) ? ('') : (
              <div className="new-update-background mt-6 lg:container mx-auto flex flex-col lg:flex-row py-3 px-5 justify-between lg:items-center bg-no-repeat bg-cover bg-center lg:rounded-lg relative">
                <div className="text-white text-xl font-medium w-3/4 mb-3 lg:mb-0">{latest_blog_post.name}</div>
                <div className="lg:space-x-7 flex items-center">
                  <Link href={"/blog/" + latest_blog_post.url}>
                    <a className="btn btn-white btn-sm">Read Update</a>
                  </Link>
                  <a
                    onClick={() => hide_latest_blog_post(latest_blog_post.id)}
                    className="cursor-pointer text-white mr-4 mt-4 lg:mt-0 lg:mr-0 lg:static leading-none hover:text-brand-secondary transition duration-150 ease-in-out">
                    <i className="fas fa-times text-xl leading-none"></i>
                  </a>
                </div>
              </div>
            )}
          </>
        ) : ('')}

        <section className="content flex flex-grow flex-col">
          <div className="pb-8 lg:pb-16">
            <div className="lg:container mx-auto mb-8 lg:mb-16">
              <div className="main_banner">
                {/* <div className="banner-layout">
                  <div className="wrap">
                    <strong className="title-scotland">Scotland&apos;s</strong>
                    <strong className="title-showroom">Premier Showroom.</strong>
                  </div>
                </div> */}
                <Slider sliders={sliders}></Slider>
                <Link href="/category/new-in">
                  <a className="btn btn-view">View The Latest Collection</a>
                </Link>
              </div>
            </div>
            <div className="lg:container mx-5 lg:mx-auto">
              {/* REMOVED AND MANUALLY ADDED BELOW, DUE TO GRAPE.JS NOT ACCEPTING RESPONSIVE CLASSES (I.E. md:grid-cols-3) */}
              {/* {(item && item.description) ? (parse(item.description)) : ('')} */}
              <div className="lg:mx-32 grid md:grid-cols-3 gap-8">
                {(trends) ? (
                  trends.data.map((item) => (
                    <Link key={item.id} href={'/category/' + item.taxonomy.url}>
                      <a className="bg-brand-primary text-center group text-white transition-all hover:bg-brand-tertiary focus:ring-2 focus:ring-brand-tertiary">
                        <div className="relative w-full category-image-holder">
                          <div className="absolute top-0 bottom-0 right-0 left-0">
                            {(item.taxonomy && item.taxonomy.media && item.taxonomy.media[0] && item.taxonomy.media[0].original_url) ? (
                              <Image
                                src={item.taxonomy.media[0].original_url}
                                alt="Placeholder Image"
                                width="420"
                                height="120"
                                className="bg-white mb-6 inline-block object-cover group-hover:filter group-hover:brightness-75"
                                placeholder="blur"
                                layout="fill"
                                objectFit="cover"
                                blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                              />
                            ) : (
                              <Image
                                src={process.env.NEXT_PUBLIC_COMING_SOON_IMAGE}
                                alt={item.title}
                                width="420"
                                height="120"
                                className="bg-white mb-6 inline-block object-cover group-hover:filter group-hover:brightness-75"
                                placeholder="blur"
                                layout="fill"
                                objectFit="cover"
                                blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                              />
                            )}
                          </div>
                        </div>
                        <div className="p-6 relative transition-all flex flex-col items-center">
                          <p className="text-sm uppercase tracking-widest">{item.title}</p>
                          <h4 className="text-xl font-semibold">{item.tagline}</h4>
                        </div>
                      </a>
                    </Link>
                  ))
                ) : ('')}
                {/* <Link href="/category/A%20Hint%20of%20Pink">
                  <a className="bg-brand-primary text-center group text-white transition-all hover:bg-brand-tertiary focus:ring-2 focus:ring-brand-tertiary">
                    <div className="relative w-full category-image-holder">
                      <div className="absolute top-0 bottom-0 right-0 left-0">
                        <Image
                          src="/assets/images/featured1_new.jpeg"
                          alt="Placeholder Image"
                          width="420"
                          height="120"
                          className="bg-white mb-6 inline-block object-cover group-hover:filter group-hover:brightness-75"
                          placeholder="blur"
                          layout="fill"
                          objectFit="cover"
                          blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                        />
                      </div>
                    </div>
                    <div className="p-6 relative transition-all flex flex-col items-center">
                      <p className="text-sm uppercase tracking-widest">A Hint of Pink</p>
                      <h4 className="text-xl font-semibold">Shop Blush Shades</h4>
                    </div>
                  </a>
                </Link>
                <Link href="/category/A%20Touch%20of%20the%20Blues">
                  <a className="bg-brand-primary text-center group text-white transition-all hover:bg-brand-tertiary focus:ring-2 focus:ring-brand-tertiary">
                    <div className="relative w-full category-image-holder">
                      <div className="absolute top-0 bottom-0 right-0 left-0">
                        <Image
                          src="/assets/images/featured2_new.jpeg"
                          alt="Placeholder Image"
                          width="420"
                          height="120"
                          className="bg-white mb-6 inline-block object-cover group-hover:filter group-hover:brightness-75"
                          placeholder="blur"
                          layout="fill"
                          objectFit="cover"
                          blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                        />
                      </div>
                    </div>
                    <div className="p-6 relative transition-all flex flex-col items-center">
                      <p className="text-sm uppercase tracking-widest">A Touch of the Blues</p>
                      <h4 className="text-xl font-semibold">Shop Blue Tones</h4>
                    </div>
                  </a>
                </Link>
                <Link href="/category/Bold%20and%20Black">
                  <a className="bg-brand-primary text-center group text-white transition-all hover:bg-brand-tertiary focus:ring-2 focus:ring-brand-tertiary">
                    <div className="relative w-full category-image-holder">
                      <div className="absolute top-0 bottom-0 right-0 left-0">
                        <Image
                          src="/assets/images/featured3_new.jpeg"
                          alt="Placeholder Image"
                          width="420"
                          height="120"
                          className="bg-white mb-6 inline-block object-cover group-hover:filter group-hover:brightness-75"
                          placeholder="blur"
                          layout="fill"
                          objectFit="cover"
                          blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                        />
                      </div>
                    </div>
                    <div className="p-6 relative transition-all flex flex-col items-center">
                      <p className="text-sm uppercase tracking-widest">Bold and Black</p>
                      <h4 className="text-xl font-semibold">Shop Black Home Decor</h4>
                    </div>
                  </a>
                </Link> */}
              </div>
            </div>
          </div>
        </section>

        <Featuredtaxonomies list={featured_taxonomies}></Featuredtaxonomies>

        {/* <Featuredproducts list={featured_products}></Featuredproducts> */}

        <div className="bg-ttd-sand-200">
          <div className="showroom-container container mx-auto">
            <div className="showroom-info-holder">
              <div className="showroom-text-info">
                {(item && item.description) ? (
                  parse(item.description)
                ) : ('')}
                <Link href="/about-us">
                  <a className="btn-arrow">View About Us</a>
                </Link>
              </div>
              <div className="showroom-image relative">
                <Link href="/our-showroom">
                  <Image
                    src="/assets/images/group-img.jpg"
                    alt="Placeholder Image"
                    placeholder="blur"
                    className="main-img"
                    layout="fill"
                    style={{
                      cursor: 'pointer',
                    }}
                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  />
                </Link>
                <div className='corner-img'>
                  <Image
                    src="/assets/images/right-image.jpg"
                    alt="Placeholder Image"
                    layout="fill"
                  />
                </div>
                <div className='right-bottom-icon'>
                  <Image
                    src="/assets/images/t-img.png"
                    alt="Placeholder Image"
                    layout="fill"
                  />
                </div>
                <div className='caption-text'>
                  Visit Our <br></br>Showroom.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-ttd-sand-200">
          <div className="container mx-auto mt-16 mb-5">
            <div className="px-5 lg:p-0">
              <h2 className="font-semibold decorative-heading inline-block text-brand-primary mr-8 mb-3 lg:mb-0">Shop Instagram</h2>
              <Link href={config.instagram}><a rel="noopener noreferrer" className="font-bold text-brand-tertiary hover:underline active:text-brand-primary" target="_blank" title="Instagram">@thetopdraweruk</a></Link>
            </div>

            <div className="related-products insta-products">
              <a className="btn-arrow-left" onClick={slideLeft}>
                <svg id="Group_918" data-name="Group 918" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g id="Ellipse_3" data-name="Ellipse 3" fill="none" stroke="#ccccce">
                    <circle cx="12" cy="12" r="12" stroke="none" />
                    <circle cx="12" cy="12" r="11.5" fill="none" />
                  </g>
                  <g id="vuesax_linear_arrow-down" data-name="vuesax/linear/arrow-down" transform="translate(5 19) rotate(-90)">
                    <g id="arrow-down">
                      <path id="Vector" d="M9.24,4.14l-3.8-3.8A1.158,1.158,0,0,0,3.8.337L0,4.14" transform="translate(2.38 4.639)" fill="none" stroke="#747c61" />
                      <path id="Vector-2" data-name="Vector" d="M0,14H14V0H0Z" transform="translate(14 14) rotate(180)" fill="none" opacity="0" />
                    </g>
                  </g>
                </svg>
              </a>
              <a className="btn-arrow-right" onClick={slideRight}>
                <svg id="Group_917" data-name="Group 917" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g id="Ellipse_3" data-name="Ellipse 3" fill="none" stroke="#ccccce">
                    <circle cx="12" cy="12" r="12" stroke="none" />
                    <circle cx="12" cy="12" r="11.5" fill="none" />
                  </g>
                  <g id="vuesax_linear_arrow-down" data-name="vuesax/linear/arrow-down" transform="translate(5 19) rotate(-90)">
                    <g id="arrow-down">
                      <path id="Vector" d="M9.24,0l-3.8,3.8A1.158,1.158,0,0,1,3.8,3.8L0,0" transform="translate(2.38 5.221)" fill="none" stroke="#747c61" />
                      <path id="Vector-2" data-name="Vector" d="M0,0H14V14H0Z" transform="translate(14 14) rotate(180)" fill="none" opacity="0" />
                    </g>
                  </g>
                </svg>
              </a>
              <div id='slider' className="instagram-feed w-full overflow-x-hidden flex flex-nowrap space-x-8 px-5 lg:p-0 mt-8 lg:mt-16">
                <div id="instafeed-container"></div>
              </div>
            </div>

            {/* <div className="instagram-feed w-full overflow-x-hidden flex flex-nowrap space-x-8 px-5 lg:p-0 mt-8 lg:mt-16">
              <div className="relative h-80 w-80 flex-none">
                <div className="absolute top-4 right-4 z-10">
                  <svg className="max-h-full lg:h-8 lg:w-8 stroke-current fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                    <g id="Group_1169" data-name="Group 1169" transform="translate(-550 -3325)">
                      <g id="Group_1168" data-name="Group 1168">
                        <rect id="Rectangle_541" data-name="Rectangle 541" width="30" height="30" rx="9" transform="translate(550 3325)"/>
                        <g id="Rectangle_586" data-name="Rectangle 586" transform="translate(554 3329)" fill="none" strokeWidth="2">
                          <rect width="30" height="30" rx="9" stroke="none"/>
                          <rect x="1" y="1" width="28" height="28" rx="8" fill="none"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <Image
                      src="/assets/images/instagram-text-image.jpg"
                      alt="Placeholder Image"
                      width="320"
                      height="320"
                      className="object-cover"
                      placeholder="blur"
                      layout="fill"
                      objectFit="cover"
                      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  />
              </div>
              <div className="relative h-80 w-80 flex-none">
                <div className="absolute top-4 right-4 z-10">
                  <svg className="max-h-full lg:h-8 lg:w-8 stroke-current fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                    <g id="Group_1169" data-name="Group 1169" transform="translate(-550 -3325)">
                      <g id="Group_1168" data-name="Group 1168">
                        <rect id="Rectangle_541" data-name="Rectangle 541" width="30" height="30" rx="9" transform="translate(550 3325)"/>
                        <g id="Rectangle_586" data-name="Rectangle 586" transform="translate(554 3329)" fill="none" strokeWidth="2">
                          <rect width="30" height="30" rx="9" stroke="none"/>
                          <rect x="1" y="1" width="28" height="28" rx="8" fill="none"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <Image
                      src="/assets/images/instagram-text-image.jpg"
                      alt="Placeholder Image"
                      width="320"
                      height="320"
                      className="object-cover"
                      placeholder="blur"
                      layout="fill"
                      objectFit="cover"
                      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  />
              </div>
              <div className="relative h-80 w-80 flex-none">
                <div className="absolute top-4 right-4 z-10">
                    <svg className="max-h-full lg:h-8 lg:w-8 stroke-current fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                      <g id="Group_1169" data-name="Group 1169" transform="translate(-550 -3325)">
                        <g id="Group_1168" data-name="Group 1168">
                          <rect id="Rectangle_541" data-name="Rectangle 541" width="30" height="30" rx="9" transform="translate(550 3325)"/>
                          <g id="Rectangle_586" data-name="Rectangle 586" transform="translate(554 3329)" fill="none" strokeWidth="2">
                            <rect width="30" height="30" rx="9" stroke="none"/>
                            <rect x="1" y="1" width="28" height="28" rx="8" fill="none"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>                
                <Image
                      src="/assets/images/instagram-text-image.jpg"
                      alt="Placeholder Image"
                      width="320"
                      height="320"
                      className="object-cover"
                      placeholder="blur"
                      layout="fill"
                      objectFit="cover"
                      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  />
              </div>
              <div className="relative h-80 w-80 flex-none">
                <div className="absolute top-4 right-4 z-10">
                  <svg className="max-h-full lg:h-8 lg:w-8 stroke-current fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                    <g id="Group_1169" data-name="Group 1169" transform="translate(-550 -3325)">
                      <g id="Group_1168" data-name="Group 1168">
                        <rect id="Rectangle_541" data-name="Rectangle 541" width="30" height="30" rx="9" transform="translate(550 3325)"/>
                        <g id="Rectangle_586" data-name="Rectangle 586" transform="translate(554 3329)" fill="none" strokeWidth="2">
                          <rect width="30" height="30" rx="9" stroke="none"/>
                          <rect x="1" y="1" width="28" height="28" rx="8" fill="none"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>

                <Image
                      src="/assets/images/instagram-text-image.jpg"
                      alt="Placeholder Image"
                      width="320"
                      height="320"
                      className="object-cover"
                      placeholder="blur"
                      layout="fill"
                      objectFit="cover"
                      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  />
              </div>
              <div className="relative h-80 w-80 flex-none">
                <div className="absolute top-4 right-4 z-10">
                  <svg className="max-h-full lg:h-8 lg:w-8 stroke-current fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                    <g id="Group_1169" data-name="Group 1169" transform="translate(-550 -3325)">
                      <g id="Group_1168" data-name="Group 1168">
                        <rect id="Rectangle_541" data-name="Rectangle 541" width="30" height="30" rx="9" transform="translate(550 3325)"/>
                        <g id="Rectangle_586" data-name="Rectangle 586" transform="translate(554 3329)" fill="none" strokeWidth="2">
                          <rect width="30" height="30" rx="9" stroke="none"/>
                          <rect x="1" y="1" width="28" height="28" rx="8" fill="none"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <Image
                      src="/assets/images/instagram-text-image.jpg"
                      alt="Placeholder Image"
                      width="320"
                      height="320"
                      className="object-cover"
                      placeholder="blur"
                      layout="fill"
                      objectFit="cover"
                      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  />
              </div>
              <div className="relative h-80 w-80 flex-none">
                <div className="absolute top-4 right-4 z-10">
                  <svg className="max-h-full lg:h-8 lg:w-8 stroke-current fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                    <g id="Group_1169" data-name="Group 1169" transform="translate(-550 -3325)">
                      <g id="Group_1168" data-name="Group 1168">
                        <rect id="Rectangle_541" data-name="Rectangle 541" width="30" height="30" rx="9" transform="translate(550 3325)"/>
                        <g id="Rectangle_586" data-name="Rectangle 586" transform="translate(554 3329)" fill="none" strokeWidth="2">
                          <rect width="30" height="30" rx="9" stroke="none"/>
                          <rect x="1" y="1" width="28" height="28" rx="8" fill="none"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>                
                <Image
                      src="/assets/images/instagram-text-image.jpg"
                      alt="Placeholder Image"
                      width="320"
                      height="320"
                      className="object-cover"
                      placeholder="blur"
                      layout="fill"
                      objectFit="cover"
                      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  />
              </div>
              <div className="relative h-80 w-80 flex-none">
                <div className="absolute top-4 right-4 z-10">
                  <svg className="max-h-full lg:h-8 lg:w-8 stroke-current fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                    <g id="Group_1169" data-name="Group 1169" transform="translate(-550 -3325)">
                      <g id="Group_1168" data-name="Group 1168">
                        <rect id="Rectangle_541" data-name="Rectangle 541" width="30" height="30" rx="9" transform="translate(550 3325)"/>
                        <g id="Rectangle_586" data-name="Rectangle 586" transform="translate(554 3329)" fill="none" strokeWidth="2">
                          <rect width="30" height="30" rx="9" stroke="none"/>
                          <rect x="1" y="1" width="28" height="28" rx="8" fill="none"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <Image
                      src="/assets/images/instagram-text-image.jpg"
                      alt="Placeholder Image"
                      width="320"
                      height="320"
                      className="object-cover"
                      placeholder="blur"
                      layout="fill"
                      objectFit="cover"
                      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  />
              </div>
              <div className="relative h-80 w-80 flex-none">
                <div className="absolute top-4 right-4 z-10">
                  <svg className="max-h-full lg:h-8 lg:w-8 stroke-current fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                    <g id="Group_1169" data-name="Group 1169" transform="translate(-550 -3325)">
                      <g id="Group_1168" data-name="Group 1168">
                        <rect id="Rectangle_541" data-name="Rectangle 541" width="30" height="30" rx="9" transform="translate(550 3325)"/>
                        <g id="Rectangle_586" data-name="Rectangle 586" transform="translate(554 3329)" fill="none" strokeWidth="2">
                          <rect width="30" height="30" rx="9" stroke="none"/>
                          <rect x="1" y="1" width="28" height="28" rx="8" fill="none"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>                
                <Image
                      src="/assets/images/instagram-text-image.jpg"
                      alt="Placeholder Image"
                      width="320"
                      height="320"
                      className="object-cover"
                      placeholder="blur"
                      layout="fill"
                      objectFit="cover"
                      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  />
              </div>
              <div className="relative h-80 w-80 flex-none">
                <div className="absolute top-4 right-4 z-10">
                  <svg className="max-h-full lg:h-8 lg:w-8 stroke-current fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                    <g id="Group_1169" data-name="Group 1169" transform="translate(-550 -3325)">
                      <g id="Group_1168" data-name="Group 1168">
                        <rect id="Rectangle_541" data-name="Rectangle 541" width="30" height="30" rx="9" transform="translate(550 3325)"/>
                        <g id="Rectangle_586" data-name="Rectangle 586" transform="translate(554 3329)" fill="none" strokeWidth="2">
                          <rect width="30" height="30" rx="9" stroke="none"/>
                          <rect x="1" y="1" width="28" height="28" rx="8" fill="none"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>                
                <Image
                      src="/assets/images/instagram-text-image.jpg"
                      alt="Placeholder Image"
                      width="320"
                      height="320"
                      className="object-cover"
                      placeholder="blur"
                      layout="fill"
                      objectFit="cover"
                      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  />
              </div>
            </div> */}
          </div>
        </div>


        <Footer menu={menu} config={config}></Footer>
      </div>
      <MiniCart></MiniCart>
      <Scripts javascripts={item.javascripts}></Scripts>
    </>
  )
}


// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation is enabled and a new request comes in
export async function getStaticProps(context) {
  const config_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/configs')
  const config_data = await config_res.json()
  const config = config_data.data

  const menu_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/menu')
  const menu = await menu_res.json()

  const res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/landingpage/home')
  const response = await res.json()
  const page = response.data

  const featured_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/featured-products/')
  const featured_response = await featured_res.json()
  const featured_products = featured_response.data

  const featured_tax_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/featured-taxonomies/')
  const featured_tax_response = await featured_tax_res.json()
  const featured_taxonomies = featured_tax_response.data

  const sliders_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/sliders/')
  const sliders_response = await sliders_res.json()
  const sliders = sliders_response.data

  const latest_blog_post_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/latest-blog')
  const latest_blog_post_response = await latest_blog_post_res.json()
  const latest_blog_post = latest_blog_post_response.data

  const trend_cat = await fetch(process.env.NEXT_PUBLIC_API_URL + '/trends-taxonomies/')
  const trend_cat_response = await trend_cat.json()

  return {
    props: {
      config: config,
      menu: menu,
      item: page,
      featured_products: featured_products,
      featured_taxonomies: featured_taxonomies,
      sliders: sliders,
      latest_blog_post: latest_blog_post,
      trends: trend_cat_response
    },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: 600, // In seconds

  }
}


export default Home
