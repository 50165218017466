import React from 'react'
import Head from 'next/head'

import OrgSchema from './schema/org';
import ProductSchema from './schema/product';

function MetaTags({ item, config }) {
    return (
        <>
            <Head>
                <title>{item.meta_title ? item.meta_title : ''}</title>
                <meta name="keywords" content={item.meta_keywords ? item.meta_keywords : ''}></meta>
                <meta name="description" content={item.meta_description ? item.meta_description : ''}></meta>
                <meta property="og:title" content={item.meta_title ? item.meta_title : ''} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={item && item.media && item.media[0] ? item.media[0].original_url : process.env.NEXT_PUBLIC_LOGO_IMAGE} />
                <meta property="og:image:url" content={item && item.media && item.media[0] ? item.media[0].original_url : process.env.NEXT_PUBLIC_LOGO_IMAGE} />
                <meta property="og:description" content={item.meta_description ? item.meta_description : ''} />
                <meta property="twitter:card" content="summary" />
                <meta property="twitter:site" content={item.meta_title ? item.meta_title : ''} />
                <meta property="twitter:title" content={item.meta_title ? item.meta_title : ''} />
                <meta property="twitter:description" content={item.meta_description ? item.meta_description : ''} />
                <meta property="twitter:image" content={item && item.media && item.media[0] ? item.media[0].original_url : process.env.NEXT_PUBLIC_LOGO_IMAGE} />
            </Head>
            <OrgSchema config={config}></OrgSchema>
            {item.variations ? <ProductSchema item={item} config={config}></ProductSchema> : ''}
        </>
    )
}

export default MetaTags;